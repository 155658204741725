"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    icons: `<iron-iconset-svg name="ez" size="24">
    <svg>
    <defs>
        <svg id="dot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M38.42 25c0 7.38-5.78 13-13.42 13s-13.42-5.62-13.42-13S17.36 
                12 25 12s13.42 5.55 13.42 13z" fill="#660174" />
        </svg>
        <svg id="home" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <defs>
                <style>
                    .cls-1 {
                        fill: #ddd
                    }
                </style>
            </defs>
            <path class="cls-1" d="M12.5 12.5h9.89v9.89H12.5zM27.61 12.5h9.89v9.89h-9.89zM12.5 27.61h25v9.89h-25z" />
        </svg>

        <svg id="stroom" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M13.73 7.5h20L28 20.55h9L17.24 42.49l1.38-16.54H13l.75-18.36z" fill="#66bb6a" />
        </svg>
        <svg id="stroom" width="24px" height="24px" viewBox="0 0 24 24" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g fill="none" fill-rule="evenodd">
                <g id="ic_flash_on_24px">
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <polygon id="Path" fill="#66BB6A" fill-rule="nonzero" points="7 2 7 13 10 13 10 22 17 10 13 10 17 2"></polygon>
                </g>
            </g>
        </svg>
        <svg id="coins" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <g id="energiekosten" transform="translate(-3.000000, -3.000000)" fill="#660174" fill-rule="nonzero">
                    <path d="M15,18.5 C12.49,18.5 10.32,17.08 9.24,15 L15,15 L15,13 L8.58,13 C8.53,12.67 8.5,12.34 
                    8.5,12 C8.5,11.66 8.53,11.33 8.58,11 L15,11 L15,9 L9.24,9 C10.32,6.92 12.5,5.5 15,5.5 C16.61,5.5 
                    18.09,6.09 19.23,7.07 L21,5.3 C19.41,3.87 17.3,3 15,3 C11.08,3 7.76,5.51 6.52,9 L3,9 L3,11 L6.06,11 
                    C6.02,11.33 6,11.66 6,12 C6,12.34 6.02,12.67 6.06,13 L3,13 L3,15 L6.52,15 C7.76,18.49 11.08,21 15,21 
                    C17.31,21 19.41,20.13 21,18.7 L19.22,16.93 C18.09,17.91 16.62,18.5 15,18.5 Z" id="Path"></path>
                </g>
            </g>
        </svg>
        <svg id="gas" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g fill="none" fill-rule="evenodd">
                <g id="ic_whatshot_24px" transform="translate(4.000000, 0.000000)" fill="#03A9F4" fill-rule="nonzero">
                    <path d="M9.5,0.67 C9.5,0.67 10.24,3.32 10.24,5.47 C10.24,7.53 8.89,9.2 6.83,9.2 C4.76,9.2 3.2,7.53 
                    3.2,5.47 L3.23,5.11 C1.21,7.51 0,10.62 0,14 C0,18.42 3.58,22 8,22 C12.42,22 16,18.42 16,14 C16,8.61 
                    13.41,3.8 9.5,0.67 Z M7.71,19 C5.93,19 4.49,17.6 4.49,15.86 C4.49,14.24 5.54,13.1 7.3,12.74 C9.07,12.38 
                    10.9,11.53 11.92,10.16 C12.31,11.45 12.51,12.81 12.51,14.2 C12.51,16.85 10.36,19 7.71,19 Z"
                    id="Shape"></path>
                </g>
            </g>
        </svg>
        <svg id="savings" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="nonzero">
                <circle cx="11" cy="11" r="11" fill="#660174" transform="translate(1 1)"/>
                <g fill="#fff">
                    <path d="M12.094 6.12l5.12 5.121H6.975zM12.094 18.068l-5.12-5.12h10.24z"/>
                </g>
            </g>
        </svg>
        <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd">
                <g fill="#660174" fill-rule="nonzero">
                    <path d="M0 2h22v5H0z"/>
                    <path d="M2 2v18H0V2zM22 2v18h-2V2z"/>
                    <path d="M0 18h22v2H0z"/>
                </g>
                <path fill="#660174" stroke="#660174" stroke-width="2" d="M17.5 12a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11z"/>
                <path fill="#fff" d="M19.834 19.092l.666.771c-.676.692-1.651 1.137-2.617 1.137-1.573 
                0-2.867-.969-3.33-2.373H13.5v-.633h.908a2.996 2.996 0 0 1-.039-.514c0-.178.01-.356.039-.524H13.5v-.633h1.062C15.035
                 14.95 16.34 14 17.932 14c.956 0 1.911.405 2.558 1.068l-.656.85c-.502-.544-1.197-.88-1.893-.88-.927 0-1.728.514-2.133 
                 1.285h2.423v.633h-2.655a2.992 2.992 0 0 0-.048.514c0 .178.019.356.058.524h2.645v.633h-2.423a2.399 2.399 0 0 0 2.133 
                 1.285c.676 0 1.371-.316 1.893-.82z"/>
                <path fill="#fff" fill-rule="nonzero" d="M3 2h4v3H3zM15 2h4v3h-4z"/>
                <path fill="#660174" fill-rule="nonzero" d="M4 0h2v4H4zM16 0h2v4h-2z"/>
            </g>
        </svg>
        <svg id="production" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M6.76,5.29 L4.96,3.5 L3.55,4.91 L5.34,6.7 L6.76,5.29 Z M4,10.95 L1,10.95 L1,12.95 L4,12.95 L4,10.95 Z M13,1 
                L11,1 L11,3.95 L13,3.95 L13,1 L13,1 Z M20.45,4.91 L19.04,3.5 L17.25,5.29 L18.66,6.7 L20.45,4.91 Z M17.24,18.61 
                L19.03,20.41 L20.44,19 L18.64,17.21 L17.24,18.61 Z M20,10.95 L20,12.95 L23,12.95 L23,10.95 L20,10.95 Z M12,5.95 
                C8.69,5.95 6,8.64 6,11.95 C6,15.26 8.69,17.95 12,17.95 C15.31,17.95 18,15.26 18,11.95 C18,8.64 15.31,5.95 12,5.95 
                Z M11,22.9 L13,22.9 L13,19.95 L11,19.95 L11,22.9 Z M3.55,18.99 L4.96,20.4 L6.75,18.6 L5.34,17.19 L3.55,18.99 Z"
                id="Shape" fill="#FFDF35" fill-rule="nonzero"></path>
            </g>
        </svg>
        <svg id="hamburger" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 50 50"><defs><style>.cls-1{fill:#fff}</style></defs><path 
            class="cls-1" d="M43.2 27.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 
            14.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 40.5a2.5 2.5 0 0 0 
            0-5H6.8a2.5 2.5 0 1 0 0 5z"/>
        </svg>

        <svg id="arrow-left" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
                <polygon id="Path" fill="#000000" fill-rule="nonzero" points="15 8.25 5.87 8.25 10.06 
                4.06 9 3 3 9 9 15 10.06 13.94 5.87 9.75 15 9.75"></polygon>
            </g>
        </svg>

        <svg id="arrow-right" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
                <polygon id="Path" fill="#000" fill-rule="nonzero" points="9 3 7.94 4.06 12.13 8.25 3 
                8.25 3 9.75 12.13 9.75 7.94 13.94 9 15 15 9"></polygon>
            </g>
        </svg>

        <svg id="menu-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86">
            <path d="M5.907 21.004c-1.352-1.338-3.542-1.338-4.894 0-1.35 1.336-1.352 3.506 0 4.844l39.54 39.15c1.352 
            1.338 3.542 1.338 4.894 0l39.54-39.15c1.351-1.338 1.352-3.506 0-4.844-1.352-1.338-3.542-1.338-4.894-.002L43 
            56.707 5.907 21.004z" fill="#fff"/>
        </svg>

        </defs>
    </svg>
</iron-iconset-svg>`
};