'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * Welcome variant
   * 1 - Welcome message with usage
   * 2 - Welcome message with mandate and meter payment options
   */
  title: 'Huidige maand',
  welcomeVariant: 2
};