'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * Scope control variants
   * 1. Scope control on bottom
   * 2. Scope control on top
   */
  scopeControlVariant: 1,
  disclaimer: 'Aan deze informatie kunnen geen rechten worden ontleend i.v.m. eventuele correcties in verbruiksdata en -kosten.'
};