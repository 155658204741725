"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lit_element_1 = require("lit-element");
exports.default = lit_element_1.css `
    /* vendor root */
    :root {
        /* Page */
        --page-position: left;
        --page-header-height: 106px;
        --background-color: #f6f6f6;

        /* Colors */
        --primary-color: #600174;
        --secondary-color: #000;

        /* Fonts */
        --font-size: 14px;
        --font-color: black;
        --font-secondary-color: #999;

        /* Links */
        --link-color: #000;
        --link-hover-color: #000;

        /* Devider */
        --divider--color: #e1e1e1;

        /* Border */
        --border-radius: 5px;
    }
`;
