'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  /**
   * Variants
   * 1. Radio button set
   * 2. Toggle link set
   */
  electricityProductionVariant: 1,

  setElectricityLabelString: '0000',
  setElectricityOffString: 'Ik heb geen stroom',
  setElectricityOnString: 'Ik wil ook stroom',
  setElectricityProductionLabelString: '0000',
  setElectricityTitle: 'Stroomverbruik per jaar',
  setEstimationToolProductTypeLabel: 'Welke energieproducten wil je afnemen?',
  setEstimationToolResidenceTypeLabel: 'Wat is het type van de woning?',
  setEstimationToolResidentsLabel: 'Hoeveel bewoners zijn er?',
  setEstimationToolTitle: 'Energieverbruik inschatten',
  setGasLabelString: '0000',
  setGasOffString: 'Ik heb geen gas',
  setGasOnString: 'Ik wil ook gas',
  setGasTitle: 'Gasverbruik per jaar',

  submitButtonString: 'Bereken maandbedrag',
  /**
   * Variants
   * 1. Full calculation tool with address picker
   * 2. Light calculation tool without address picker
   */
  variant: 1
};