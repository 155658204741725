'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    appTitle: 'Independer',
    logoRedirect: 'https://www.independer.nl/mijnindepender/dashboard',
    electricityAccentColor: '#03a9f4',
    electricityProductionAccentColor: '#FFDF35',
    gasAccentColor: '#03A9F4',
    savingsAccentColor: '#600174',
    logoAppLink: 'https://www.independer.nl/mijnindepender/dashboard',
    logOutLink: 'https://www.independer.nl/mijnindepender/dashboard',
    vendorID: 'c4eb01ba-f63e-11e9-802a-5aa538984bd8',
    hideOneSnapshot: true,
    hideNewContractButton: true,
    showSupplierInformation: true,
    smartCharging: false,
    onboarding: false
};