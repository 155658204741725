'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Wachtwoord vergeten?',
    body: 'Vul je e-mailadres in en klik op verstuur. Je ontvangt van ons per e-mail een link om je wachtwoord opnieuw in te stellen.',
    inputPlaceholder: 'naam@website.nl',
    submit: 'verstuur'
};