'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
// @ts-nocheck
exports.default = {
  menuPositionVariant: 1,
  menuSelectedPostAgree: 3,
  menuSelectedPreAgree: 2,
  /**
   * Menu position variants
   * 1. Sign mandate and pay for meter on page
   * 2. Show button to go to app and sign mandate and pay for meter in app
   */
  postAgreeVariant: 1,
  termsOfServiceURL: 'https://www.independer.nl/',
  vendor: 'Independer',
  vendorPhoneNumber: '010 3074890'
};