'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    getDataString: type => {
        switch (type) {
            case 'production':
                return 'teruggeleverd aan\nhet stroomnet';
            case 'coins':
                return 'verbruik in\nenergiekosten';
            default:
                return 'verbruikt van het\n%snet';
        }
    },
    getNoDataString: type => {
        switch (type) {
            case 'production':
                return 'nog geen\nstroomaansluiting\nverbonden';
            case 'coins':
                return 'nog geen\nenergiekosten\nbeschikbaar';
            default:
                return 'nog geen\n%saansluiting\nverbonden';
        }
    }
};