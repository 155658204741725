'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Sorry, wij kunnen deze pagina niet vinden',
    body: 'Maar we blijven zoeken. Geef ons een paar minuten en probeer het daarna gewoon nog een keer. Graag tot zo.',
    disableLogin: true,
    emailPlaceholder: '',
    passwordPlaceholder: '',
    submit: '',
    forgotPassword: '',
    newCustomer: ''
};