'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    title: 'Ben jij al klant?',
    bodyText: `Heb jij al een energiecontract bij ons en wil jij een extra adres toevoegen of een aflopend energiecontract verlengen.`,
    buttonExistingCustomerText: 'Ja, ik ben al klant',
    buttonNewCustomerText: 'Nee, ik ben nieuw',
    backButtonText: 'Vorige stap'
};