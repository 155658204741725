"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
// @ts-ignore
exports.default = {
    icons: `<iron-iconset-svg name="ez-menu" size="24">
    <svg>
    <defs>
        <svg id="dot" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M38.42 25c0 7.38-5.78 13-13.42 13s-13.42-5.62-13.42-13S17.36 
                12 25 12s13.42 5.55 13.42 13z" fill="#660174" />
        </svg>
        <svg id="home" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon fill="#600174" fill-rule="nonzero" points="10 20 10 14 14 14 14 20 19 20 19 12 22 12 
                12 3 2 12 5 12 5 20">
                </polygon>
            </g>
        </svg>
        <svg id="stroom" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <polygon id="Path" fill="#600174" fill-rule="nonzero" points="7 2 7 13 10 13 10 22 17 10 13 10 17 2"></polygon>
            </g>
        </svg>
        <svg id="gas" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <g transform="translate(4.000000, 0.000000)" fill="#600174" fill-rule="nonzero">
                    <path d="M9.5,0.67 C9.5,0.67 10.24,3.32 10.24,5.47 C10.24,7.53 8.89,9.2 6.83,9.2 C4.76,9.2 
                    3.2,7.53 3.2,5.47 L3.23,5.11 C1.21,7.51 0,10.62 0,14 C0,18.42 3.58,22 8,22 C12.42,22 16,18.42 
                    16,14 C16,8.61 13.41,3.8 9.5,0.67 Z M7.71,19 C5.93,19 4.49,17.6 4.49,15.86 C4.49,14.24 5.54,13.1 
                    7.3,12.74 C9.07,12.38 10.9,11.53 11.92,10.16 C12.31,11.45 12.51,12.81 12.51,14.2 C12.51,16.85 
                    10.36,19 7.71,19 Z"></path>
                </g>
            </g>
        </svg>
        <svg id="production" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <path d="M6.76,5.29 L4.96,3.5 L3.55,4.91 L5.34,6.7 L6.76,5.29 Z M4,10.95 L1,10.95 
                L1,12.95 L4,12.95 L4,10.95 Z M13,1 L11,1 L11,3.95 L13,3.95 L13,1 L13,1 Z M20.45,4.91 
                L19.04,3.5 L17.25,5.29 L18.66,6.7 L20.45,4.91 Z M17.24,18.61 L19.03,20.41 L20.44,19 
                L18.64,17.21 L17.24,18.61 Z M20,10.95 L20,12.95 L23,12.95 L23,10.95 L20,10.95 Z M12,5.95 
                C8.69,5.95 6,8.64 6,11.95 C6,15.26 8.69,17.95 12,17.95 C15.31,17.95 18,15.26 18,11.95 
                C18,8.64 15.31,5.95 12,5.95 Z M11,22.9 L13,22.9 L13,19.95 L11,19.95 L11,22.9 Z M3.55,18.99 
                L4.96,20.4 L6.75,18.6 L5.34,17.19 L3.55,18.99 Z" fill="#600174" fill-rule="nonzero"></path>
            </g>
        </svg>
        <svg id="invoices" xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
            <path fill="#660174" fill-rule="nonzero" d="M4.25 4v13.333h10.833V4H4.25zm7.5-1.667H2.583V14H.917V.667H11.75v1.666z"/>
        </svg>
        <svg id="savings" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <g fill="none" fill-rule="nonzero">
                <circle cx="9.167" cy="9.167" r="9.167" fill="#660174"/>
                <g fill="#fff">
                    <path d="M9.245 4.267l4.267 4.267H4.978zM9.245 14.224L4.978 9.957h8.534z"/>
                </g>
            </g>
        </svg>
        <svg id="account" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user-circle"
        class="svg-inline--fa fa-user-circle fa-w-16"
         role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="#660174" 
         d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 
         39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 
         18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 
         40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z">
         </path></svg>
         <svg id="invoices" xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
            <path fill="#660174" fill-rule="nonzero" d="M4.25 4v13.333h10.833V4H4.25zm7.5-1.667H2.583V14H.917V.667H11.75v1.666z"/>
        </svg>
        <svg id="coins" width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
            <g stroke="none" fill="none" fill-rule="evenodd">
                <g id="energiekosten" transform="translate(-3.000000, -3.000000)" fill="#660174" fill-rule="nonzero">
                    <path d="M15,18.5 C12.49,18.5 10.32,17.08 9.24,15 L15,15 L15,13 L8.58,13 C8.53,12.67 8.5,12.34 
                    8.5,12 C8.5,11.66 8.53,11.33 8.58,11 L15,11 L15,9 L9.24,9 C10.32,6.92 12.5,5.5 15,5.5 C16.61,5.5 
                    18.09,6.09 19.23,7.07 L21,5.3 C19.41,3.87 17.3,3 15,3 C11.08,3 7.76,5.51 6.52,9 L3,9 L3,11 L6.06,11 
                    C6.02,11.33 6,11.66 6,12 C6,12.34 6.02,12.67 6.06,13 L3,13 L3,15 L6.52,15 C7.76,18.49 11.08,21 15,21 
                    C17.31,21 19.41,20.13 21,18.7 L19.22,16.93 C18.09,17.91 16.62,18.5 15,18.5 Z" id="Path"></path>
                </g>
            </g>
        </svg>
        <svg id="hamburger" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 50 50"><defs><style>.cls-1{fill:#fff}</style></defs><path 
            class="cls-1" d="M43.2 27.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 
            14.5a2.5 2.5 0 0 0 0-5H6.8a2.5 2.5 0 1 0 0 5zM43.2 40.5a2.5 2.5 0 0 0 
            0-5H6.8a2.5 2.5 0 1 0 0 5z"/></svg>
        <svg id="arrow-left" aria-hidden="true" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" ` + `viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-3x">
            <path fill="#fff" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 ` + `0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 ` + `24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
        </svg>
        <svg id="arrow-right" aria-hidden="true" data-prefix="fas" data-icon="arrow-right" role="img" ` + `xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-right fa-w-14 fa-3x">
            <path fill="#fff" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 ` + `33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 ` + `0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" class=""></path>
        </svg>
        <svg id="address" aria-hidden="true" focusable="false" 
        data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12"
        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97
             99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817
              80 80 80z"></path>
        </svg>

        <svg id="addresses" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path fill="#600174" d="M11 42q-1.25 0-2.125-.875T8 39V19.5q0-.7.325-1.35.325-.65.875-1.05l13-9.75q.4-.3.85-.45.45-.15.95-.15.5 0
            .95.15.45.15.85.45l13 9.75q.55.4.875 1.05.325.65.325 1.35V39q0 1.25-.875 2.125T37 42h-9V28h-8v14Z"/>
        </svg>
        <svg id="logout" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path fill="#600174" 
            d="M32.25 31.65q-.45-.45-.45-1.1 0-.65.45-1.05l4-4h-16q-.65 0-1.075-.425-.425-.425-.425-1.075
            0-.65.425-1.075.425-.425 1.075-.425h15.9l-4.05-4.05q-.4-.4-.4-1.025 0-.625.45-1.075.45-.45 1.075-.45t1.075.45L40.95
            23q.25.25.35.5.1.25.1.55 0 .3-.1.55-.1.25-.35.5l-6.6 6.6q-.4.4-1.025.4-.625 0-1.075-.45ZM9 42q-1.2 0-2.1-.9Q6 40.2
            6 39V9q0-1.2.9-2.1Q7.8 6 9 6h13.05q.65 0 1.075.425.425.425.425 1.075 0 .65-.425
            1.075Q22.7 9 22.05 9H9v30h13.05q.65 0 1.075.425.425.425.425 1.075 0 .65-.425 1.075Q22.7 42 22.05 42Z"/>
        </svg>
        </defs>
    </svg>
</iron-iconset-svg>`
};