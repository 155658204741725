'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// @ts-nocheck
exports.default = {
    scanBarcodeHeaderText: 'Geef hier je meternummer(s) door',
    scanBarcodeParagraphText: `De laatste stap! Om je slimme meter te kunnen koppelen hebben we de 
    laatste 6 cijfers van je meternummer nodig. Dat nummer staat op de barcode van je slimme meter in je meterkast. 
    Heb je stroom én gas? Voer dan eerst één nummer in en klik op ‘Meter koppelen’, doe vervolgens het hetzelfde voor het andere nummer. 
    De volgorde maakt niet uit.`,
    idinHeaderText: 'Activeer je Independer verbruiksinzicht',
    idinParagraphText: `Om er zeker van te zijn dat jij de enige bent met inzicht in je verbruiksdata, doen we één laatste controle op je adres. 
    Je kan dit doen door je te identificeren met iDIN of door je meternummer(s) door te geven. Meer informatie over iDIN vind je `,
    idinPrimaryButtonText: 'Identificeren met iDIN',
    idinSecondaryButtonText: 'Meternummer(s) doorgeven',
    idinAboutLinkText: 'hier.',
    idinAboutLink: 'https://www.idin.nl/',
    logoAppLink: 'https://www.independer.nl/mijnindepender/dashboard'
};