"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const lit_element_1 = require("lit-element");
exports.styles = lit_element_1.css `
    flex-rates-component {
        /* variables */
        --primary-color: #dedede;
    }
`;
exports.default = {
    // styles
    styles: exports.styles,
    barColor: '#600174',
    barHoverColor: '#8505a0',
    lineColor: '#95a4ac',
    axisLabelColor: 'black',
    legendaInformation: ``,
};
